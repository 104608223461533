<template>
  <div class="card bg-dark border-secondary mb-2" style="width: 100%">
    <div class="card-body">
      <div class="text-center">
        <QrcodeVue
          :value="preferences.qr"
          :size="200"
          level="H"
          background="transparent"
          foreground="#3498db"
        />
      </div>
      <ul class="list-group mt-4">
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <span class="font-weight-bold">Käytetty:</span>
          <span v-if="isUsed()" class="text-danger"
            >Kyllä tämä on käytetty</span
          >
          <span v-else class="text-success">Ei oo käytetty vielä</span>
        </li>
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <span class="font-weight-bold">Lisätty:</span>
          <span class="text-info">{{ formatDate(preferences.added) }}</span>
        </li>
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <span class="font-weight-bold">Vasen luku:</span>
          <span class="text-info">{{ preferences.first }}</span>
        </li>
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <span class="font-weight-bold">Oikea luku:</span>
          <span class="text-info">{{ preferences.second }}</span>
        </li>
        <li
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <span class="font-weight-bold">QR-koodin arvo:</span>
          <span class="text-info">{{ cut(preferences.qr) }}</span>
        </li>
      </ul>

      <div class="text-center">
        <button class="btn btn-danger mt-3" @click="remove()">Poista</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import QrcodeVue from "qrcode.vue";
import { format } from "date-fns";

export default {
  name: "QrCard",
  methods: {
    ...mapMutations(["deleteQr"]),
    formatDate(date) {
      return format(new Date(date), "D.M.YYYY H:m");
    },
    cut(qr) {
      return qr.substring(0, 15) + "...";
    },
    remove() {
      this.deleteQr(this.preferences.id);
    },
    isUsed() {
      return this.$store.getters.qrUsed(this.preferences.id);
    },
  },
  props: ["preferences"],
  components: { QrcodeVue },
};
</script>
