<template>
  <div class="empty">
    <p class="title">Osta matkalippu</p>
    <div class="btn-container">
      <PurchaseBtn iconName="single-34">
        <template v-slot:title>Kertalippu</template>
        <template v-slot:description>Kerta- ja lisävyöhykeliput</template>
      </PurchaseBtn>
      <PurchaseBtn iconName="week-34"
        ><template v-slot:title>Vuorokausilippu</template>
        <template v-slot:description>1 – 13 vrk </template></PurchaseBtn
      >
      <PurchaseBtn iconName="period-34"
        ><template v-slot:title>Kausilippu</template>
        <template v-slot:description
          >Jatkuva tilaus tai kertaosto
        </template></PurchaseBtn
      >
    </div>
    <div class="link-container">
      <div class="link">
        <div class="link-wrapper">
          <div class="img-wrapper">
            <img src="@/assets/icons/phone.svg" />
          </div>
          <div class="text">
            <span class="title">Lippu koodilla</span>
            <span class="description">Lunasta lippu koodilla</span>
          </div>
        </div>
      </div>
      <div class="link">
        <div class="link-wrapper">
          <div class="img-wrapper">
            <img src="@/assets/icons/bike.svg" />
          </div>
          <div class="text">
            <span class="title">Kaupunkipyörät</span>
            <span class="description"
              >Osta käyttöoikeutta päiväksi, viikoksi tai koko kaudeksi.</span
            >
          </div>
          <div class="img-wrapper-2">
            <img src="@/assets/icons/link.svg" />
          </div>
        </div>
      </div>
    </div>
    <div class="final-link">
      <p>Ohjeet, hinnat ja ehdot</p>
    </div>
  </div>
</template>

<script>
import PurchaseBtn from "@/components/ticket/buy/PurchaseBtn.vue";

export default {
  name: "Buy",
  components: { PurchaseBtn },
};
</script>

<style lang="scss" scoped>
p.title {
  font-size: 29px;
  margin: 0 0 30px 15px;
}
.btn-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.empty {
  width: 100%;
  max-width: calc(100vw - 2 * #{$main-container-padding});
  height: 100%;

  margin-top: 30vh;
}

.link-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  margin: 20px 0;

  .link {
    width: 100%;
    height: 60px;

    border-bottom: 1px solid $gray4;
    .link-wrapper {
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;

      &:active {
        opacity: 0.25;
      }
    }
    .img-wrapper {
      flex-shrink: 0;

      width: 80px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    .img-wrapper-2 {
      flex-shrink: 0;

      width: 65px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        font-size: 15px;

        color: $blue;

        margin-bottom: 1px;
      }
      .description {
        font-size: 12px;
        font-family: "Gotham Rounded Book";

        color: $gray2;
      }
    }
  }
}

.final-link {
  padding-left: 20px;

  p {
    font-size: 14px;
    color: $blue;
  }

  &:active {
    opacity: 0.25;
  }
}
</style>
