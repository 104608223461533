<template>
  <div v-if="!loading">
    <div class="news-wrapper">
      <NewsItem
        v-for="item in news"
        :key="item.id"
        :data="item"
        class="news-item-wrapper"
      ></NewsItem>
    </div>
    <div class="show-more">
      <span>Näytä lisää</span>
    </div>
  </div>
  <div class="loading" v-else>
    <img src="@/assets/icons/loading.svg" />
  </div>
</template>

<script>
import NewsItem from "@/components/news/NewsItem.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "News",
  data: () => ({
    loading: true,
  }),
  async mounted() {
    if (this.news.length === 0) await this.fetchNews();
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["fetchNews"]),
  },
  computed: {
    ...mapState(["news"]),
  },
  components: { NewsItem },
};
</script>

<style lang="scss" scoped>
.news-wrapper {
  width: 100vw;

  padding: 0 15px;

  display: flex;
  flex-direction: column;

  background: $white;

  .news-item-wrapper:not(:last-child) {
    border-bottom: 1px solid $gray4;
  }

  border-bottom: 15px solid $gray4;
}

.show-more {
  width: 100%;
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    width: 112px;
    height: 28px;

    border: 1px solid $gray1;
    border-radius: 14px;

    color: $gray1;

    font-size: 15px;
    letter-spacing: -0.5px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:active {
      opacity: 0.25;
    }
  }
}

.loading {
  position: absolute;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 40px;
    animation: load 800ms infinite linear;
    @keyframes load {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
    }
  }
}
</style>
