import { createRouter, createWebHistory } from "vue-router";
import Hsl from "../views/Hsl.vue";
import Ticket from "../views/hsl/Ticket.vue";
import Display from "../views/hsl/ticket/Display.vue";
import Buy from "../views/hsl/ticket/Buy.vue";
import Home from "../views/hsl/Home.vue";
import News from "../views/hsl/home/News.vue";
import Traffic from "../views/hsl/home/Traffic.vue";
import Guide from "../views/hsl/Guide.vue";
import More from "../views/hsl/More.vue";
import History from "../views/hsl/History.vue";
import Settings from "../views/hidden/Settings.vue";

const You = News;

const routes = [
  {
    path: "/",
    redirect: "/hsl",
  },
  {
    path: "/hsl",
    name: "Hsl",
    component: Hsl,
    children: [
      {
        path: "",
        redirect: "/hsl/ticket",
      },
      {
        path: "ticket",
        component: Ticket,
        children: [
          {
            path: "",
            redirect: "/hsl/ticket/display",
          },
          {
            path: "display",
            name: "Display",
            component: Display,
          },
          {
            path: "buy",
            name: "Buy",
            component: Buy,
          },
        ],
      },
      {
        path: "home",
        component: Home,
        children: [
          {
            path: "",
            redirect: "/hsl/home/you",
          },
          {
            path: "you",
            name: "You",
            component: You,
          },
          {
            path: "news",
            name: "News",
            component: News,
          },
          {
            path: "traffic",
            name: "Traffic",
            component: Traffic,
          },
        ],
      },
      {
        path: "guide",
        component: Guide,
      },
      {
        path: "more",
        component: More,
      },
      {
        path: "history",
        component: History,
      },
    ],
  },
  {
    name: "Hidden",
    path: "/hidden",
    component: () =>
      import(/* webpackChunkName: "Hidden" */ "../views/Hidden.vue"),
    children: [
      { path: "", redirect: "/hidden/settings" },
      {
        path: "settings",
        name: "Settings",
        component: Settings,
      },
      {
        path: "chat",
        name: "Chat",
        component: () =>
          import(/* webpackChunkName: "Chat" */ "../views/hidden/Chat.vue"),
      },
      {
        path: "tutorial",
        name: "Tutorial",
        component: () =>
          import(
            /* webpackChunkName: "Tutorial" */ "../views/hidden/Tutorial.vue"
          ),
      },
    ],
  },
  { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(to => {
  if (to?.matched[0]?.name !== "Hsl") {
    document.querySelector("html").classList.remove("blackout");
  }
  if (to?.matched[1]?.name !== "Chat") {
    document.body.style.overflowY = "";
  } else {
    document.body.style.overflowY = "hidden";
  }
});

router.afterEach((to, from) => {
  if (to?.matched[0]?.name === "Hsl" && from?.matched[0]?.name === "Hidden") {
    window.location.reload();
  }
});

export default router;
