<template>
  <Frame>
    <div class="wrapper">
      <span
        >Sinulla ei ole<br />
        voimassaolevia <br />
        lippuja</span
      >
      <button @click="toSettings()">Osta lippu</button>
    </div>
  </Frame>
</template>

<script>
import Frame from "./Frame";

export default {
  name: "NoTicket",
  methods: {
    toSettings() {
      this.$router.push("/hidden/settings");
    },
  },
  components: { Frame },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-size: 23px;

    color: $gray2;

    text-align: center;

    margin-bottom: 60px;
  }

  button {
    width: 225px;
    height: 50px;

    border: none;
    border-radius: 25px;

    background-color: $blue;

    color: $white;

    font-size: 17px;
    font-weight: normal;

    &:active {
      opacity: 0.25;
    }
  }
}
</style>
