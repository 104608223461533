<template>
  <footer ref="footer">
    <div class="wrapper">
      <Nav linkTo="/hsl/home" iconName="home">KOTI</Nav>
      <Nav linkTo="/hsl/ticket" :also="['/hsl/history']" iconName="ticket"
        >LIPUT</Nav
      >
      <Nav linkTo="/hsl/guide" iconName="map">REITTIOPAS</Nav>
      <Nav linkTo="/hsl/more" iconName="hamburger">LISÄÄ</Nav>
    </div>
  </footer>
</template>

<script>
import Nav from "./Nav";

export default {
  name: "Footer",
  mounted() {
    this.$refs.footer.addEventListener(
      "touchmove",
      function(e) {
        e.preventDefault();
      },
      false
    );
  },
  components: { Nav },
};
</script>

<style lang="scss" scoped>
footer {
  position: fixed;

  box-sizing: content-box;

  bottom: 0;
  left: 0;
  z-index: 1111;

  width: 100%;
  height: $footer-h;

  padding-bottom: env(safe-area-inset-bottom);

  background-color: $white;

  border-top: 1px solid dim(#000);

  .wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
