<template>
  <div>
    <NoTicket v-if="tickets.length === 0" />
    <Ticket
      v-else
      v-for="(ticket, index) in tickets"
      :preferences="ticket"
      :key="index"
    />
    <More v-show="tickets.length !== 0" />
  </div>
</template>

<script>
import Ticket from "@/components/ticket/ticket/Ticket";
import NoTicket from "@/components/ticket/ticket/NoTicket";
import More from "@/components/ticket/more/More";

import { mapState } from "vuex";
import { isFuture } from "date-fns";

export default {
  name: "Main",
  data: () => ({
    interval: null,
  }),
  mounted() {
    const colorExpiryInterval = 1000 * 5;
    this.interval = setInterval(this.checkColorExpiry, colorExpiryInterval);
    this.checkColorExpiry();
  },
  methods: {
    async checkColorExpiry() {
      const expiry = new Date(this?.color?.expiry);
      if (!isFuture(expiry)) {
        await this.$store.dispatch("fetchColor");
      }
    },
  },
  computed: mapState(["tickets", "color"]),
  components: { Ticket, More, NoTicket },
};
</script>

<style lang="scss" scoped></style>
