<template>
  <header ref="header">
    <Nav v-for="(link, index) in links" :key="index" :linkTo="link.linkTo">{{
      link.title
    }}</Nav>
  </header>
</template>

<script>
import Nav from "./Nav";

export default {
  name: "Header",
  mounted() {
    this.$refs.header.addEventListener(
      "touchmove",
      function(e) {
        e.preventDefault();
      },
      false
    );
  },
  props: ["links"],
  components: { Nav },
};
</script>

<style lang="scss" scoped>
header {
  height: $header-h;
  width: 100%;

  position: fixed;
  box-sizing: content-box;
  z-index: 99;

  top: 0;
  left: 0;
  padding-top: env(safe-area-inset-top);

  background-color: $blue;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
