<template>
  <div class="wrapper">
    <qrcode-vue
      class="qr"
      :value="qr"
      :size="size"
      :level="level"
      :background="background"
      :foreground="foreground"
      :renderAs="renderAs"
    />
    <div class="texts">
      <span class="bold">{{ customQr ? first : leftDigits }}</span
      ><span class="thin">{{ second }}</span>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";

const randomize = require("randomatic");

export default {
  name: "Qr",
  data: () => ({
    size: 132,
    background: "rgba(0,0,0,0)",
    foreground: "#333333",
    level: "H",
    renderAs: "canvas",
    customQr: false,
    qr: "",
    first: "",
    second: "",
  }),
  created() {
    if (this.qrId === "random") {
      this.customQr = false;
      this.qr = randomize("*", 109);
      this.second = randomize("0", 8);
    } else {
      this.customQr = true;
      const qr = this.$store.getters.qrById(this.qrId);
      this.qr = qr.qr;
      this.first = qr.first;
      this.second = qr.second;
    }
  },
  components: { QrcodeVue },
  props: ["qrId", "leftDigits"],
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .texts {
    margin-top: 30px;
    span {
      font-size: 14px;
      &.bold {
        font-weight: normal;
        margin-right: 5px;
      }
      &.thin {
        font-family: "Gotham Rounded Book";
      }
    }
  }
}
</style>
