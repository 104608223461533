<template>
  <div class="wrapper">
    <div class="padding">
      <div class="top">
        <span class="title">Tarvitsetko lipun myös kaverille?</span>
        <router-link to="/hsl/ticket/buy" class="button"
          >Osta uusi lippu</router-link
        >
      </div>
      <div class="bottom">
        <span class="title">Aiemmat lippuostosi</span>
        <router-link to="/hsl/history" class="link"
          >Ostoshistoria <img src="@/assets/icons/caret.svg"
        /></router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "More",
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  margin-top: 5px;

  display: flex;
  justify-content: center;

  .padding {
    width: 75vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .top,
    .bottom {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      span.title {
        font-family: "Gotham Rounded Book";
        font-size: 14px;
      }
    }

    .top {
      .button {
        text-decoration: none;

        width: 225px;
        height: 40px;

        margin: 20px 0;

        border: none;
        border-radius: 20px;

        background-color: $blue;

        color: $white;

        font-size: 17px;
        font-weight: normal;

        display: flex;
        justify-content: center;
        align-items: center;

        &:active {
          opacity: 0.25;
        }
      }
    }
    .bottom {
      border-top: 1px solid $gray4;

      .title {
        margin: 20px 0;
      }
      .link {
        color: $blue;

        text-decoration: none;

        font-size: 17px;

        &:active {
          opacity: 0.25;
        }
      }
    }
  }
}
</style>
