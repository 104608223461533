<template>
  <div class="message" :class="fetchError ? 'warning' : 'success'">
    <img
      :src="
        require('@/assets/icons/' +
          (fetchError ? 'warning' : 'success') +
          '.svg')
      "
    />
    <span v-if="fetchError">Värien haku epäonnistui</span>
    <span v-else>Värien haku onnistui</span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: mapState(["fetchError"]),
  name: "Message",
};
</script>

<style lang="scss" scoped>
.message {
  position: absolute;

  z-index: 88;

  opacity: 0;

  $message-h: 60px;
  width: 100%;
  height: $message-h;

  box-sizing: content-box;
  padding-top: env(safe-area-inset-top);

  $low-h: calc(0px - (#{$message-h} - #{$header-h}));
  top: $low-h;
  left: 0;

  &.warning {
    background-color: $red;
  }
  &.success {
    background-color: $lgreen;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 15px;
  }

  span {
    font-size: 14px;
    font-weight: normal;

    color: $white;
  }

  $wait: 100ms;
  $appear: 500ms;
  $stay: 3000ms;
  $disappear: 500ms;

  animation: appear $appear ease-out, disappear $disappear ease-in;
  animation-delay: $wait, calc(#{$wait} + #{$stay} + #{$appear});
  animation-fill-mode: forwards;
  @keyframes appear {
    0% {
      top: $low-h;
      opacity: 1;
    }
    100% {
      top: $header-h;
      opacity: 1;
    }
  }
  @keyframes disappear {
    0% {
      top: $header-h;
      opacity: 1;
    }
    99% {
      opacity: 1;
    }
    100% {
      top: $low-h;
      opacity: 0;
    }
  }
}
</style>
