<template>
  <div class="wrapper">
    <div class="left">
      <div class="text">
        <span v-if="!expired" class="title">Voimassa</span>
        <span v-else class="title">Ei voimassa</span>
        <span class="value"
          ><img
            :src="
              require('@/assets/icons/' +
                (expired ? 'inactive' : 'active') +
                '.svg')
            "
            class="status"
          />{{ formatExpiry(expiry) }}
        </span>
      </div>
    </div>
    <div class="right">
      <div class="text">
        <span class="title">Asiakasryhmä</span>
        <span v-if="age === 'child'" class="value">Lapsi 7-17v</span>
        <span v-else-if="age === 'adult'" class="value">Aikuinen</span>
        <span v-else-if="age === 'student'" class="value">Opiskelija</span>
      </div>
      <img
        src="@/assets/icons/child.svg"
        class="child"
        v-show="age === 'child'"
      />
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  name: "Info",
  methods: {
    formatExpiry(date) {
      let numeric;
      if (this.ticketType === "single") {
        numeric = format(new Date(date), "H:mm");
        if (this.expired) {
          return `Päättyi klo ${numeric}`;
        }
      } else {
        numeric = format(new Date(date), "D.M.YYYY");
        if (this.expired) {
          return `Päättyi ${numeric}`;
        }
      }
      return `${numeric} asti`;
    },
  },
  props: ["age", "expiry", "ticketType", "expired"],
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  .left,
  .right {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-size: 12px;
      font-family: "Gotham Rounded Book";

      color: $gray1;

      margin-bottom: 5px;
    }
    .value {
      font-size: 17px;
      font-weight: normal;
    }
  }
  .right {
    .text {
      align-items: flex-end;
    }
    img {
      margin-left: 5px;
      align-self: flex-end;
    }
  }
  .status {
    margin-right: 5px;
  }
}
</style>
