<template>
  <div>
    <p class="text-danger text-center" v-if="!balls">
      Tämähän on tietenkin erittäin suuri riski. En siis suosittele olla näin
      isomunanen. Jos jäät kiinni, saat enemmän kuin sakkoja.
      <button
        class="btn btn-warning text-dark mt-3"
        @click="balls = true"
        style="width: 14rem"
      >
        Minulla on rautaiset pallit
      </button>
      <br />
      <small class="text-white">Oletko jo tehnyt tämän?</small>
      <br />
      <button
        class="btn btn-info text-dark mt-1"
        v-scroll-to="'#qrs'"
        style="width: 14rem"
      >
        Haluan katsella QR-koodejani
      </button>
    </p>
    <div v-else>
      <p class="text-info">
        Pyydä kaveriasi lähettämään näyttökuva lipustaan. Jos kaverisi seisoo
        vieressäsi, voit myös tällä sovelluksella ottaa kuvan hänen
        QR-koodistaan.
      </p>
      <div class="text-center mb-3" v-show="!found">
        <button
          class="btn btn-info mr-1"
          :class="{ active: stream }"
          @click="
            stream = !stream;
            roll = false;
          "
        >
          Kamera 📹
        </button>
        <button
          class="btn btn-info"
          :class="{ active: roll }"
          @click="
            roll = !roll;
            stream = false;
          "
        >
          Kamerarulla 🖼️
        </button>
      </div>
      <QrStream
        v-if="stream"
        @decode="onDecode"
        @init="onInit"
        :camera="camera"
        class="mb-3"
      >
        <div v-if="loading" class="loading">
          <span>Venaa... 🏖️</span>
        </div>

        <div v-else>
          <button
            class="btn btn-sm btn-dark mt-1 ml-1"
            @click="switchCamera('auto')"
            :class="{
              'text-success': camera === 'auto',
              active: camera === 'auto',
            }"
          >
            Peruskamera 📷
          </button>
          <br />
          <button
            class="btn btn-sm btn-dark mt-1 ml-1"
            :class="{
              disabled: noRearCamera,
              'text-danger': noRearCamera,
              'text-success': camera === 'rear',
              active: camera === 'rear',
            }"
            @click="switchCamera('rear')"
          >
            Takakamera 📷
          </button>
          <br />
          <button
            class="btn btn-sm btn-dark mt-1 ml-1"
            :class="{
              disabled: noFrontCamera,
              'text-danger': noFrontCamera,
              'text-success': camera === 'front',
              active: camera === 'front',
            }"
            @click="switchCamera('front')"
          >
            Etukamera 📷
          </button>
          <div
            class="
              footer
              bg-secondary
              d-flex
              align-items-center
              justify-content-center
            "
          >
            <span v-show="error" class="badge badge-danger"
              >QR-koodi virheellinen</span
            >
          </div>
        </div>
      </QrStream>
      <div class="custom-file mb-3" v-else-if="roll">
        <QrCapture
          @decode="onDecode"
          :multiple="false"
          :name="null"
          :capture="null"
          class="custom-file-input"
        />
        <label class="custom-file-label" data-browse="Kamerarulla"
          >Etsi näyttökuva 📱</label
        >
        <div class="text-center mt-1">
          <span v-show="error" class="text-danger"
            >Sori mut tuo QR-koodi ei kelpaa.</span
          >
        </div>
      </div>
      <div class="alert alert-dismissible alert-success mt-1" v-show="found">
        <h4 class="alert-heading">Hyvä! Koodi löytyi!</h4>
        <p class="mb-0">
          Koodi on kaikenlisäks oikeassa muodossa, joten saatat ehkä
          mahdollisesti selviä tästä!
        </p>
      </div>
      <p class="text-info">
        Kaverisi lipun QR-koodin alla on kaksi lukua. Syötä ne tänne. (Varmuuden
        vuoksi)
      </p>
      <div>
        <div
          class="form-group"
          :class="firstCorrect ? 'has-success' : 'has-danger'"
        >
          <label class="form-control-label">Vasen 👈</label>
          <input
            type="number"
            pattern="\d*"
            class="form-control"
            :class="firstCorrect ? 'is-valid' : 'is-invalid'"
            placeholder="382619"
            v-model="first"
          />
          <div v-if="firstCorrect" class="valid-feedback">
            Nyt näyttää oikealta!
          </div>
          <div v-else class="invalid-feedback">
            Ömm... Nyt ei näytä hyvältä. 🤮
          </div>
        </div>

        <div
          class="form-group"
          :class="secondCorrect ? 'has-success' : 'has-danger'"
        >
          <label class="form-control-label">Oikea 👉</label>
          <input
            type="number"
            pattern="\d*"
            class="form-control"
            :class="secondCorrect ? 'is-valid' : 'is-invalid'"
            placeholder="37194820"
            v-model="second"
          />
          <div v-if="secondCorrect" class="valid-feedback">
            Nyt näyttää oikealta!
          </div>
          <div v-else class="invalid-feedback">
            Ömm... Nyt ei näytä hyvältä. 🤮
          </div>
        </div>

        <button class="btn btn-success" @click="saveQr()">Tallenna</button>
        <br />
        <small class="text-warning"
          >Muista, että tämä vanhenee, kun kaverisi lippu vanhenee. Joten,
          poista tämä sitten, kun se on turha. Sen voi tehdä "Minun QR-koodit"
          -osiosta.</small
        >
      </div>
    </div>
  </div>
</template>

<script>
import { QrStream, QrCapture } from "vue3-qr-reader";
import { mapMutations } from "vuex";

const short = require("short-uuid");

export default {
  name: "Qr",
  data: () => ({
    balls: false,
    loading: false,
    stream: false,
    roll: false,
    error: false,
    found: false,
    string: "",
    camera: "auto",
    noRearCamera: false,
    noFrontCamera: false,
    first: "",
    second: "",
  }),
  methods: {
    ...mapMutations(["addQr"]),
    onDecode(string) {
      if (string.length === 108 && window.atob(string)) {
        this.found = true;
        this.error = false;
        this.string = string;
        this.roll = false;
        this.stream = false;
      } else {
        this.error = true;
      }
    },
    async onInit(promise) {
      this.loading = true;

      try {
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
          this.camera = "auto";
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
          this.camera = "auto";
        }
      } finally {
        this.loading = false;
      }
    },
    switchCamera(direction) {
      this.camera = direction;
    },
    saveQr() {
      if (!this.firstCorrect || !this.secondCorrect || !this.found) return;

      const qr = {
        id: short.generate(),
        added: new Date().toISOString(),
        qr: this.string,
        first: this.first,
        second: this.second,
      };

      this.addQr(qr);

      this.string = "";
      this.first = "";
      this.second = "";

      this.found = false;
      this.balls = false;
    },
  },
  computed: {
    firstCorrect() {
      return this.first.toString().match("^[0-9]{3,}$");
    },
    secondCorrect() {
      return this.second.toString().match("^[0-9]{3,}$");
    },
  },
  components: { QrStream, QrCapture },
};
</script>

<style>
.loading {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
}
</style>
