<template>
  <div class="item-wrapper">
    <div class="icon">
      <img :src="require('@/assets/icons/more/' + data.icon + '.svg')" />
    </div>
    <div class="text">
      <span class="title">{{ data.title }}</span>
      <span class="desc">{{ data.desc }}</span>
    </div>
    <div class="link">
      <img v-if="data.link" src="@/assets/icons/link.svg" />
      <img v-else src="@/assets/icons/more/caret.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ListItem",
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  width: 100%;
  min-height: 80px;

  display: flex;
  align-items: center;

  background-color: $white;

  border-top: 1px solid $gray4;

  &:active * {
    opacity: 0.5;
  }

  .icon,
  .link {
    width: 80px;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    flex: 1;

    display: flex;
    flex-direction: column;

    .title {
      font-size: 17px;

      color: $blue;

      margin-bottom: 3px;
    }
    .desc {
      font-family: "Gotham Rounded Book";

      font-size: 13px;
      letter-spacing: -0.5px;

      color: $gray1;
    }
  }
}
</style>
