<template>
  <div class="card bg-dark border-secondary mb-2" style="width: 100%">
    <div class="card-header">
      <span v-if="!preferences.expired" class="text-success">Voimassa ✅</span>
      <span v-else class="text-danger">Ei voimassa</span>
    </div>
    <div class="card-body">
      <div v-show="!preferences.expired">
        <h5>Lipputyyppi 🎟️</h5>
        <div class="mb-4">
          <span v-if="preferences.type === 'single'"
            >Kertalippu <span class="text-info">(80 minuuttia)</span></span
          >
          <span v-else-if="preferences.type === 'week'"
            >Vuorokausilippu <span class="text-info"></span>(1 vrk - 13
            vrk)</span
          >
          <span v-else-if="preferences.type === 'period'"
            >Kausilippu <span class="text-info">(14 vrk - 360 vrk)</span></span
          >
        </div>
        <h5>Aikaa jäljellä ⏰</h5>
        <p class="text-info mb-3">
          {{ formatRemaining(preferences.expiry) }}
        </p>
        <h5>Asiakasryhmä 🔞</h5>
        <select class="custom-select mb-3" v-model="age">
          <option value="adult" selected>Aikuinen</option>
          <option value="child">Lapsi (7-17)</option>
          <option value="student">Opiskelija</option>
        </select>
        <h5>Matkustusalue 🔠</h5>
        <select class="custom-select mb-3" v-model="area">
          <option value="AB" selected>AB</option>
          <option value="ABC">ABC</option>
          <option value="ABCD">ABCD</option>
          <option value="BC">BC</option>
          <option value="BCD">BCD</option>
          <option value="CD">CD</option>
          <option value="D">D</option>
        </select>
        <h5>QR-koodi 🤓</h5>
        <select class="custom-select mb-3" v-model="qrId">
          <option value="random" selected>Ihan random vaa</option>
          <option
            v-for="(qr, index) in qrs"
            :key="index"
            :value="qr.id"
            :disabled="isUsed(qr.id)"
          >
            Lisätty: {{ formatQrAdded(qr.added) }}
          </option>
        </select>
      </div>
      <div class="text-center">
        <button class="btn btn-danger" @click="deleteTicket(preferences.id)">
          Poista tämä lippu
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import datetimeDifference from "datetime-difference";
import { format } from "date-fns";

export default {
  name: "Pref",
  methods: {
    ...mapMutations(["setAge", "setArea", "setType", "deleteTicket", "setQr"]),
    formatRemaining(date) {
      const now = new Date();
      const expiry = new Date(date);

      const d = datetimeDifference(now, expiry);
      const formatted = `${d.months}kk ${d.days}p ${d.hours}h ${d.minutes}m`;
      return formatted;
    },
    formatQrAdded(date) {
      return format(new Date(date), "D.M.YYYY H:m");
    },
    isUsed(qrId) {
      return this.$store.getters.qrUsed(qrId);
    },
  },
  computed: {
    ...mapState(["qrs"]),
    age: {
      get() {
        return this.preferences.age;
      },
      set(newValue) {
        const id = this.preferences.id;
        this.setAge({ id, age: newValue });
      },
    },
    area: {
      get() {
        return this.preferences.area;
      },
      set(newValue) {
        const id = this.preferences.id;
        this.setArea({ id, area: newValue });
      },
    },
    type: {
      get() {
        return this.preferences.type;
      },
      set(newValue) {
        const id = this.preferences.id;
        this.setType({ id, type: newValue });
      },
    },
    qrId: {
      get() {
        const qrId = this.preferences.qr;
        if (this.$store.getters.qrExists(qrId)) {
          return qrId;
        } else {
          this.setQr({ qrId: "random", ticketId: this.preferences.id });
          return "random";
        }
      },
      set(newValue) {
        this.setQr({ qrId: newValue, ticketId: this.preferences.id });
      },
    },
  },

  props: ["preferences"],
};
</script>
