<template>
  <div class="header-title" ref="header">Asetukset</div>
  <div class="list">
    <ListItem
      v-for="item in firstItems"
      :data="item"
      :key="item.title"
    ></ListItem>
    <div class="blank"></div>
    <ListItem
      v-for="item in secondItems"
      :data="item"
      :key="item.title"
    ></ListItem>
  </div>
  <div class="credit" @click="openSettings()">Ohjeet, hinnat ja ehdot</div>
  <div class="version">Sovellusversio 3.4.0 (104169)</div>
  <div class="credit">Avoimen lähdekoodin lisenssit</div>
</template>

<script>
import ListItem from "@/components/more/ListItem";

export default {
  name: "More",
  data: () => ({
    firstItems: [
      {
        title: "Asetukset",
        desc: "Ostoshistoria, ilmoitukset...",
        icon: "settings",
        link: false,
      },
      {
        title: "Asiakaspalvelu",
        desc: "Palvelu- ja myyntipisteet, yhteydenotot",
        icon: "customer",
        link: true,
      },
      {
        title: "Palaute",
        desc: "Lähetä palautetta",
        icon: "feedback",
        link: false,
      },
    ],
    secondItems: [
      {
        title: "Kaupunkipyörät",
        desc: "Käyttöönotto ja palvelunhallinta",
        icon: "bike",
        link: true,
      },
      {
        title: "HSL.fi",
        desc: "Tietoa joukkoliikenteestä ja kaikista lipputuotteista",
        icon: "web",
        link: true,
      },
    ],
  }),
  methods: {
    openSettings() {
      this.$router.push("/hidden/settings");
    },
  },
  mounted() {
    this.$refs.header.addEventListener(
      "touchmove",
      function(e) {
        e.preventDefault();
      },
      false
    );
  },
  components: { ListItem },
};
</script>

<style lang="scss" scoped>
.header-title {
  width: 100%;
  position: fixed;
  z-index: 99;

  top: 0;
  left: 0;

  box-sizing: content-box;
  padding-top: env(safe-area-inset-top);

  height: $header-h;

  background-color: $blue;
  color: $white;

  font-size: 19px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.list {
  margin-top: calc(#{$header-h} + env(safe-area-inset-top));
}

.blank {
  width: 100%;
  height: 10px;
  border-top: 2px solid $gray4;
}
.credit,
.version {
  font-size: 15px;
  border-top: 1px solid $gray4;

  padding: 15px 0;

  display: flex;
  justify-content: center;
  align-items: center;
}
.credit {
  color: $blue;

  &:active {
    opacity: 0.25;
  }
}
.version {
  font-family: "Gotham Rounded Book";
  color: $gray1;
}
</style>
