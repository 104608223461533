<template>
  <div class="button">
    <div class="img-wrapper">
      <img :src="require('@/assets/icons/' + iconName + '.svg')" />
    </div>
    <div class="text">
      <span class="title"><slot name="title"></slot></span>
      <span class="description"><slot name="description"></slot></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "PurchaseBtn",
  props: ["iconName"],
};
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  height: 68px;

  background-color: $blue;

  border-radius: 8px;

  margin: 5px 0;

  display: flex;
  align-items: center;

  color: $white;

  .img-wrapper {
    flex-shrink: 0;

    width: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .title {
      font-size: 18px;
    }
    .description {
      font-size: 12px;
      font-family: "Gotham Rounded Book";
    }
  }
  &:active {
    filter: brightness(75%);
  }
}
</style>
