import { createStore } from "vuex";
import axios from "axios";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    tickets: state.tickets,
    color: state.color,
    fetchDate: state.fetchDate,
    fetchError: state.fetchError,
    fetchErrorCause: state.fetchErrorCause,
    default: state.default,
    showWelcome: state.showWelcome,
    qrs: state.qrs,
    blackoutMode: state.blackoutMode,
    svgOn: state.svgOn,
  }),
});

const backend = process.env.VUE_APP_ROOT_API;
const hslGQL = "https://content.app.hsl.fi/graphql";

export default createStore({
  state: {
    showWelcome: true,

    blackoutMode: false,

    svgOn: true,

    news: [],
    traffic: [],

    modalOpen: false,
    modalArea: "AB",
    fetchError: true,
    fetchErrorCause: "",
    fetchDate: "",
    color: {
      value: "black",
      expiry: "",
    },
    default: {
      age: "adult",
      area: "AB",
    },
    tickets: [],
    qrs: [],
  },
  getters: {
    color: state => state.color.value,
    svgOn: state => state.svgOn,
    qrById: state => id => state.qrs.find(qr => qr.id === id),
    qrUsed: state => id =>
      state.tickets.findIndex(ticket => ticket.qr === id) > -1,
    qrExists: state => id => state.qrs.findIndex(qr => qr.id === id) > -1,
  },
  mutations: {
    openModal: (state, area) => {
      state.modalOpen = true;
      state.modalArea = area;
    },
    closeModal: state => (state.modalOpen = false),
    updateColor: (state, json) => {
      const data = json.data;
      state.color = {
        value: data.color,
        expiry: data.utcExpiry,
      };
    },
    expireTicket: (state, id) => {
      state.tickets.find(ticket => ticket.id === id).expired = true;
    },
    addTicket(state, preferences) {
      state.tickets.push({ ...preferences, expired: false });
    },
    deleteTicket(state, id) {
      const index = state.tickets.findIndex(n => n.id === id);
      state.tickets.splice(index, 1);
    },
    setAge(state, details) {
      state.tickets.find(ticket => ticket.id === details.id).age = details.age;
    },
    setArea(state, details) {
      state.tickets.find(ticket => ticket.id === details.id).area =
        details.area;
    },
    setType(state, details) {
      state.tickets.find(ticket => ticket.id === details.id).type =
        details.type;
    },
    setDefaultArea(state, area) {
      state.default.area = area;
    },
    setDefaultAge(state, age) {
      state.default.age = age;
    },
    noWelcome(state) {
      state.showWelcome = false;
    },
    addQr(state, preferences) {
      state.qrs.push(preferences);
    },
    deleteQr(state, id) {
      const index = state.qrs.findIndex(n => n.id === id);
      state.qrs.splice(index, 1);
    },
    setQr(state, { qrId, ticketId }) {
      state.tickets.find(ticket => ticket.id === ticketId).qr = qrId;
    },
    updateBlackoutMode(state, bool) {
      state.blackoutMode = bool;
    },
    updateSvgOn(state, bool) {
      state.svgOn = bool;
    },
    updateNews(state, news) {
      state.news = news;
    },
    updateTraffic(state, traffic) {
      state.traffic = traffic;
    },
  },
  actions: {
    async fetchColor({ state, commit }) {
      try {
        const json = await axios.get(backend, {
          timeout: 1000 * 10,
          headers: {
            santa: "maria",
          },
        });
        state.fetchError = false;
        state.fetchErrorCause = "";
        commit("updateColor", json);
      } catch (error) {
        state.fetchError = true;
        if (!error.response) {
          state.fetchErrorCause = "Verkko-ongelma";
        } else {
          const code = error.response.status;
          switch (code) {
            case 404:
              state.fetchErrorCause = "Serveri ei löytynyt";
              break;
            case 429:
              state.fetchErrorCause = "Liikaa pyyntöjä";
              break;
            case 500:
              state.fetchErrorCause = "Serveri ei löytänyt värejä";
              break;
            default:
              state.fetchErrorCause = "Tuntematon";
          }
        }
      } finally {
        state.fetchDate = new Date().toISOString();
      }
    },
    async fetchNews({ commit }) {
      const json = await axios({
        url: hslGQL,
        method: "post",
        data: {
          query: `
          query NewsAllQuery {
            newsFeedContent(limit: 20, page: null, lang: "fi") {
              id
              created
              label
              ... on NewsArticle {
                body
                url
                images {
                  url
                }
                priority {
                  label
                }
              }
            }
          }
          `,
        },
      });

      commit("updateNews", json.data.data.newsFeedContent);
    },
    async fetchTraffic({ commit }) {
      const json = await axios({
        url: hslGQL,
        method: "post",
        data: {
          query: `
          query trafficBulletinsQuery {
            trafficBulletins(lang: "fi", page: null, limit: 60) {
              id
              label
              created
              changed
              priority {
                label
              }
              validDateFrom
              validDateTo
              lines {
                gtfsId
                label
                id
              }
            }
          }          
          `,
        },
      });

      commit("updateTraffic", json.data.data.trafficBulletins);
    },
  },
  modules: {},
  plugins: [vuexLocal.plugin],
});
