<template>
  <Header :links="links" />

  <div class="home-wrapper">
    <router-view v-slot="{ Component }">
      <transition :name="animationName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import Header from "@/components/header/Header";

export default {
  name: "Home",
  data: () => ({
    animationName: undefined,
    links: [
      {
        linkTo: "/hsl/home/you",
        title: "Sinulle",
      },
      {
        linkTo: "/hsl/home/traffic",
        title: "Liikenne",
      },
      {
        linkTo: "/hsl/home/news",
        title: "Uutiset",
      },
    ],
  }),
  watch: {
    $route(to, from) {
      this.animationName =
        from.name === "News" || to.name === "You"
          ? "slide-left"
          : "slide-right";
    },
  },
  components: { Header },
};
</script>

<style lang="scss" scoped>
.home-wrapper {
  width: 100vw;
  height: auto;

  padding-top: $header-h;
  padding-top: calc(#{$header-h} + env(safe-area-inset-top));

  background-color: $background;
}
</style>

<style scoped>
div[class*="-active"] {
  transition: all 250ms ease-out;
}
div[class*="slide-"] {
  position: absolute;
}

.slide-left-enter-to,
.slide-right-leave-from {
  left: 0;
}

.slide-left-enter-from,
.slide-right-leave-to {
  left: -100%;
}

.slide-left-leave-to,
.slide-right-enter-from {
  right: -100%;
}

.slide-left-leave-from,
.slide-right-enter-to {
  right: 0;
}
</style>
