<template>
  <div class="container bg-secondary py-3 mt-2">
    <table class="table table-hover">
      <tbody>
        <tr class="table-dark">
          <th scope="row">Väri</th>
          <td v-if="updating">
            <div class="spinner-grow spinner-grow-sm text-warning"></div>
          </td>
          <td v-else class="text-info">
            {{ colorHex() }}<br />
            {{ colorRgb() }}<br />
            <div class="example" :style="{ background: color.value }"></div>
          </td>
        </tr>
        <tr class="table-dark">
          <th scope="row">Vanhenee</th>
          <td v-if="updating">
            <div class="spinner-grow spinner-grow-sm text-warning"></div>
          </td>
          <td v-else class="text-info">
            {{ color.expiry ? formatExpiry(color.expiry) : "Ei hajuakaan" }}
          </td>
        </tr>
        <tr class="table-dark">
          <th scope="row">Päivitetty</th>
          <td v-if="updating">
            <div class="spinner-grow spinner-grow-sm text-warning"></div>
          </td>
          <td v-else class="text-info">
            {{ fetchDate ? formatDate(fetchDate) : "Ei tietoa" }}
          </td>
        </tr>
        <tr class="table-dark">
          <th scope="row">Onnistuiko?</th>
          <td v-if="updating">
            <div class="spinner-grow spinner-grow-sm text-warning"></div>
          </td>
          <td v-else-if="!fetchError" class="text-success">Onnistui!</td>
          <td v-else class="text-danger">
            Ei onnistunut! <br /><b>SYY: </b>
            <span class="text-warning">{{ fetchErrorCause }}</span>
          </td>
        </tr>
        <tr class="table-dark" v-show="fetchError">
          <th scope="row"></th>
          <td class="text-warning">
            Väri toimii kuitenkin kunnes se vanhenee.
          </td>
        </tr>
      </tbody>
    </table>

    <div class="text-center">
      <button
        v-if="!updating"
        type="button"
        class="btn btn-dark btn-outline-success"
        @click="updateColor()"
      >
        <b>Päivitä värit nyt! 😡</b><br />( saa, mutta <i>ei tarvitse</i> )
      </button>
      <button
        v-else
        class="btn btn-dark btn-outline-success"
        type="button"
        disabled
      >
        <span class="spinner-border spinner-border-sm"></span>
        Venaa hetki...
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { format } from "date-fns";

const hexRgb = require("hex-rgb");

export default {
  name: "ColorInfo",
  data: () => ({
    updating: false,
  }),
  methods: {
    async updateColor() {
      this.updating = true;
      await this.$store.dispatch("fetchColor");
      this.updating = false;
    },
    colorHex() {
      const color = this.color.value.toUpperCase();
      if (color.match("#[0-9A-z]{6}")) {
        return color;
      } else {
        return "Ei mikään";
      }
    },
    colorRgb() {
      try {
        return hexRgb(this.color.value, { format: "css" });
      } catch {
        return "Ei mikään";
      }
    },
    formatDate(date) {
      return format(new Date(date), "D.M.YYYY \n H:mm.ss");
    },
    formatExpiry(date) {
      return format(new Date(date), "D.M.YYYY \n H:mm");
    },
  },
  computed: {
    ...mapState(["color", "fetchError", "fetchErrorCause", "fetchDate"]),
  },
};
</script>

<style lang="scss" scoped>
.example {
  width: 100%;
  height: 1rem;

  margin-top: 0.5rem;
  border-radius: 0.25rem;
  box-shadow: 0px 1px 2px #000;
}
td {
  user-select: text;
  -webkit-user-select: text !important;
  -webkit-tap-highlight-color: unset !important;
  -webkit-touch-callout: text !important;
}
</style>
