<template>
  <div class="ios-wrap">
    <div class="dad">
      <div class="bg-dark rounded mb-2 d-flex" id="top">
        <div
          class="
            bg-secondary
            d-flex
            justify-content-center
            align-items-center
            rounded
            back-btn
          "
          style="width: 5rem"
          @click="goBack()"
        >
          ⬅️
        </div>
        <div class="flex-grow-1 text-center py-3">
          <h1 class="display-4">Asetukset</h1>

          <button
            class="btn btn-outline-success mt-2"
            @click="goChat()"
            style="width: 9rem"
          >
            Chatti 💬
          </button>
          <br />
          <button
            class="btn btn-outline-info mt-3"
            v-scroll-to="'#tickets'"
            style="width: 9rem"
          >
            Liput 🎟️
          </button>
          <br />
          <button
            class="btn btn-outline-info mt-2"
            v-scroll-to="'#qrs'"
            style="width: 9rem"
          >
            QR-koodit 🤓
          </button>
        </div>
      </div>

      <div class="card border-danger text-white mb-2" v-show="showWelcome">
        <div class="card-header">
          <h4 class="card-title text-center">Tervetuloa asetuksiin!</h4>
        </div>
        <div class="card-body text-center">
          <span>Tänne pääsee jatkossa vain salaisella tavalla:</span><br />
          <button class="btn btn-info my-2" @click="showSecret = !showSecret">
            {{
              !showSecret ? "Näytä salainen tapa!" : "Okei, muistan sen nyt!"
            }}
          </button>
          <img
            src="@/assets/instructions/hidden.png"
            class="img-fluid"
            v-show="showSecret"
          />
          <br />
          <span>
            Sinun kannattaa käydä tarkistuslista läpi ennen kuin alat käyttämään
            tätä sovellusta: </span
          ><br />
          <button v-scroll-to="'#checklist'" class="btn btn-info mt-2">
            Tarkistuslista
          </button>
        </div>
        <div class="card-footer text-center">
          <button class="btn btn-sm btn-warning text-dark" @click="noWelcome()">
            Kova juttu!<br />Älä näytä mulle tätä viestiä enää ikinä.
          </button>
        </div>
      </div>

      <div class="card border-success mb-2 opportunity">
        <div class="card-body">
          <span
            >Tienasin viime kuussa 800€ pienellä vaivalla ottamalla valokuvia.
            Jos tämä kiinnostaa</span
          ><br />
          <a href="https://51.fi/gj2" target="_blank">paina tästä 💸</a>
        </div>
      </div>

      <div class="card border-danger mb-2">
        <div class="card-body">
          <p class="text-danger">
            MUISTA, että qr koodi on feikki, eli SE EI TOIMI. Älä siis näytä
            tätä lippua tarkastajille.
          </p>
          <p>
            Tämä sovellus ei ole tehty vittuilakseen HSL:lle, joten jos sinulla
            on varaa ostaa aito lippu, tee se.
          </p>
        </div>
      </div>

      <Card>
        <template v-slot:title>Väri 🎨</template>
        <template v-slot:subtitle>Säädä lipun väriä</template>
        <template v-slot:content>
          <p class="mb-4">
            Väri päivittyy <b class="text-success">automaattisesti. 🤖</b
            ><br />Väri on siis aina oikea!
          </p>
          <div class="text-center">
            <button
              type="button"
              class="btn btn-outline-warning"
              :class="{ active: showColorInfo }"
              @click="showColorInfo = !showColorInfo"
            >
              {{ showColorInfo ? "Älä näytäkkään" : "Näytä statsei 📈" }}
            </button>
          </div>
          <ColorInfo v-show="showColorInfo" />
        </template>
      </Card>
      <Card>
        <template v-slot:title>Oletusasetukset 🛠️</template>
        <template v-slot:subtitle
          >Valitse sinulle sopivat oletusasetukset</template
        >
        <template v-slot:content>
          <label>Asiakasryhmä 🔞</label>
          <select class="custom-select mb-3" v-model="defaultAge">
            <option value="adult" selected>Aikuinen</option>
            <option value="child">Lapsi (7-17)</option>
            <option value="student">Opiskelija</option>
          </select>
          <label>Matkustusalue 🔠</label>
          <select class="custom-select mb-3" v-model="defaultArea">
            <option value="AB" selected>AB</option>
            <option value="ABC">ABC</option>
            <option value="ABCD">ABCD</option>
            <option value="BC">BC</option>
            <option value="BCD">BCD</option>
            <option value="CD">CD</option>
            <option value="D">D</option>
          </select>
          <label
            >Pimennys-turvakytkin 🌗
            <p class="small my-0">
              Näyttösi "sammuu" lipputilassa, jos laitteesi ei ole pystytilassa.
            </p></label
          >
          <div class="custom-control custom-switch" @click="check('Blackout')">
            <input
              type="checkbox"
              class="custom-control-input"
              ref="checkboxBlackout"
              v-model="blackoutMode"
            />
            <label class="custom-control-label">{{
              blackoutMode ? "Käytössä 🌚" : "Ei käytössä 🌞"
            }}</label>
          </div>
          <label class="mt-3"
            >Animaatiotekniikka 🎬
            <p class="small my-0">
              <b class="text-info">SVG</b> on varmempi, mutta
              <b class="text-info">Video</b> saattaa olla sujuvampi. Testaa!
            </p></label
          >
          <div class="custom-control custom-switch" @click="check('Animation')">
            <input
              type="checkbox"
              class="custom-control-input"
              ref="checkboxAnimation"
              v-model="svgOn"
            />
            <label class="custom-control-label">{{
              svgOn ? "SVG" : "Video"
            }}</label>
          </div>
        </template>
      </Card>
      <Card id="checklist">
        <template v-slot:title>Tarkistuslista ✅</template>
        <template v-slot:subtitle
          >Tästä näet, hyödynnätkö sovellusta täysillä</template
        >
        <template v-slot:content>
          <p>
            <span class="text-warning font-weight-bold"
              >Haluat ehdottomasti,</span
            >
            että tämä sovellus näyttäisi sovellukselta, eikä vaan
            nettisivulta.<br /><br />
            Jotta näin olisi, sinun on lisättä tämä sivu kotinäytöllesi. Jos
            tiedät miten se tehdään, kova juttu.<br /><br />
            Jos et,
            <router-link to="/hidden/tutorial">
              tästä löydät ohjeet.</router-link
            >
          </p>
          <ul class="list-group mt-4">
            <li
              class="list-group-item d-flex align-items-center font-weight-bold"
            >
              <i
                class="bi mr-3"
                :class="[
                  homescreen ? 'bi-check2' : 'bi-x',
                  homescreen ? 'text-success' : 'text-danger',
                ]"
                style="font-size: 1.5rem"
              ></i>
              Sovellus on lisätty kotinäytölle
            </li>
            <li class="list-group-item d-flex align-items-center">
              <i
                class="bi mr-3"
                :class="[
                  portrait ? 'bi-check2' : 'bi-x',
                  portrait ? 'text-success' : 'text-danger',
                ]"
                style="font-size: 1.5rem"
              ></i>
              Laitteesi on pystytilassa
            </li>
          </ul>
          <ul class="list-group mt-4">
            <li
              class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <span>Eikö jokin toimi?</span>
              <button class="btn btn-danger" @click="resetApp()">
                Resetoi sovellus
              </button>
            </li>
          </ul>
        </template>
      </Card>
      <Card id="addQr">
        <template v-slot:title>QR-koodi 🤓</template>
        <template v-slot:subtitle
          >Jos kaverillasi on oikea lippu, voit lainata sitä</template
        >
        <template v-slot:content>
          <Qr />
        </template>
      </Card>

      <div style="min-height: 100vh" id="tickets">
        <div class="jumbotron text-center mb-2">
          <h1 class="display-4">Lippusi</h1>
          <p :class="tickets.length > 0 ? 'text-info' : 'text-danger'">
            Sinulla on tällä hetkellä {{ tickets.length }}
            {{ tickets.length === 1 ? "lippu" : "lippua" }}
          </p>
          <button
            class="btn btn-secondary mb-1"
            v-scroll-to="'#top'"
            style="width: 8rem"
          >
            Ylös ⏫</button
          ><br />
          <button
            class="btn btn-info"
            :class="{ 'btn-dark': showSlider }"
            style="width: 8rem"
            @click="showSlider = !showSlider"
          >
            <span v-if="!showSlider"><i>Osta</i> uusi lippu</span>
            <span v-else>Sulje ✋</span>
          </button>
          <div v-show="showSlider" class="mt-1 p-2 bg-dark">
            <span>Kuinka pitkäksi ajaksi haluat lippusi? 🕘</span>
            <input
              type="range"
              class="custom-range mt-1"
              min="1"
              max="24"
              step="1"
              v-model="sliderValue"
            />
            <span
              >Näköjään
              <span class="text-info">{{ ticketExpiry() }}.</span></span
            ><br />
            <button class="btn btn-success mt-4" @click="buyTicket()">
              Osta! 🤑
            </button>
          </div>
        </div>

        <div>
          <Pref
            v-for="(ticket, index) in tickets"
            :key="index"
            :preferences="ticket"
          />
        </div>
      </div>

      <div style="min-height: 100vh" id="qrs">
        <div class="jumbotron text-center mb-2">
          <h1 class="display-4">QR-koodisi</h1>
          <p :class="qrs.length > 0 ? 'text-info' : 'text-danger'">
            Sinulla on tällä hetkellä {{ qrs.length }}
            {{ qrs.length === 1 ? "koodi" : "koodia" }}
          </p>
          <button
            class="btn btn-secondary mb-1"
            v-scroll-to="'#top'"
            style="width: 8rem"
          >
            Ylös ⏫</button
          ><br />
          <button
            class="btn btn-info"
            style="width: 8rem"
            v-scroll-to="{
              el: '#addQr',
              cancelable: false,
              offset: halfHeight,
            }"
          >
            Lisää QR-koodi
          </button>
        </div>

        <div class="">
          <QrCard v-for="(qr, index) in qrs" :key="index" :preferences="qr" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../../components/settings/Card";
import Pref from "../../components/settings/Pref";
import ColorInfo from "../../components/settings/ColorInfo";
import Qr from "../../components/settings/Qr";
import QrCard from "../../components/settings/QrCard";

import { mapState, mapMutations } from "vuex";
import { addMinutes, addDays, format } from "date-fns";

const short = require("short-uuid");

export default {
  name: "Settings",
  data: () => ({
    showColorInfo: false,
    showSecret: false,

    showSlider: false,
    sliderValue: 1,
    days: undefined,
    ticketType: "",

    portrait: false,
  }),
  mounted() {
    window.addEventListener("orientationchange", this.checkOrientation);
    this.checkOrientation();
  },
  methods: {
    ...mapMutations([
      "addTicket",
      "setDefaultAge",
      "setDefaultArea",
      "noWelcome",
      "updateBlackoutMode",
      "updateSvgOn",
    ]),
    resetApp() {
      window.localStorage.clear();
      window.caches.keys().then(function(names) {
        for (let name of names) window.caches.delete(name);
      });
      window.location.reload();
    },
    checkOrientation() {
      this.portrait =
        window?.screen?.orientation?.angle === 0 || window?.orientation === 0;
    },
    goBack() {
      this.$router.push({ name: "Display" });
    },
    goChat() {
      this.$router.push({ name: "Chat" });
    },
    ticketExpiry() {
      const value = parseInt(this.sliderValue);
      let days;
      if (value === 1) {
        this.ticketType = "single";
        return "80 minuutiksi";
      } else {
        if (value <= 13) {
          this.ticketType = "week";
          days = value;
        } else if (value === 14) {
          this.ticketType = "period";
          days = value;
        } else {
          this.ticketType = "period";
          days = value === 24 ? 360 : 30 + (value - 15) * 30;
        }
        this.days = days;
        return `${days} vuorokaudeksi`;
      }
    },
    buyTicket() {
      const id = short.generate();

      const type = this.ticketType;

      let expiry;
      if (type === "single") {
        expiry = addMinutes(new Date(), 80).toISOString();
      } else {
        expiry = addDays(new Date(), this.days).toISOString();
      }

      const age = this.defaultAge;
      const area = this.defaultArea;

      const leftDigits = format(new Date(), "HHmmss");
      const qr = "random";

      this.addTicket({
        id,
        expiry,
        age,
        type,
        area,
        qr,
        leftDigits,
      });

      this.sliderValue = 1;
      this.showSlider = false;
    },
    check(el) {
      this.$refs["checkbox" + el].click();
    },
    closeVideo() {
      this.iphoneVideo = false;
      this.androidVideo = false;
    },
  },
  computed: {
    ...mapState({
      tickets: "tickets",
      qrs: "qrs",
      defaultPreferences: "default",
      showWelcome: "showWelcome",
    }),
    homescreen() {
      return window.navigator?.standalone === true;
    },
    blackoutMode: {
      get() {
        return this.$store.state.blackoutMode;
      },
      set(newVal) {
        this.updateBlackoutMode(newVal);
      },
    },
    svgOn: {
      get() {
        return this.$store.state.svgOn;
      },
      set(newVal) {
        this.updateSvgOn(newVal);
      },
    },
    defaultAge: {
      get() {
        return this.defaultPreferences.age;
      },
      set(newVal) {
        this.setDefaultAge(newVal);
      },
    },
    defaultArea: {
      get() {
        return this.defaultPreferences.area;
      },
      set(newValue) {
        this.setDefaultArea(newValue);
      },
    },
    halfHeight: () => -1 * Math.round(window.outerHeight / 4),
  },
  watch: {
    cached(value) {
      if (value === true) {
        this.list[2].completed = this.list[1].completed;
      }
    },
  },
  components: { Card, Pref, ColorInfo, Qr, QrCard },
};
</script>

<style lang="scss" scoped>
.dad {
  height: 100%;

  .opportunity {
    background-image: linear-gradient(109deg, red, green, blue, pink);
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: bolder;
    color: yellow;
    text-shadow: 2px 2px 2px red;
  }
}
</style>
