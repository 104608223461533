<template>
  <div class="card border-secondary mb-2" style="width: 100%">
    <div class="card-body">
      <h5 class="card-title">
        <slot name="title"></slot>
      </h5>
      <h6 class="card-subtitle mb-2 text-muted">
        <slot name="subtitle"></slot>
      </h6>
      <div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
};
</script>
