<template>
  <div class="traffic-item">
    <div class="top-text">
      <span class="time">{{ formatTime(data.created) }}</span>
      <span class="tag" v-show="important">HYVÄ TIETÄÄ</span>
    </div>
    <div class="other-info">
      <div class="icon-wrapper" :class="important ? 'warning' : 'info'">
        <img
          :src="require('@/assets/icons/traffic/list/' + iconSrc + '.svg')"
        />
      </div>
      <div class="text">
        <div class="details">
          <div class="lines" :class="iconSrc">
            <span class="line" v-for="line in data.lines" :key="line.id">{{
              line.label
            }}</span>
          </div>
          <span class="valid-from-to">{{
            formatValid(data.validDateFrom, data.validDateTo)
          }}</span>
        </div>
        <span class="title">{{ data.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("fi");

export default {
  name: "TrafficItem",
  methods: {
    formatTime(date) {
      const momentCreated = moment(date);
      const isToday = moment().isSame(momentCreated, "day");
      if (momentCreated.isAfter(moment().subtract(1, "hours"))) {
        return momentCreated.fromNow();
      } else if (isToday) {
        return momentCreated.format("LT").replace(/^\w/, c => c.toUpperCase());
      } else {
        return momentCreated.format("l").replace(/^\w/, c => c.toUpperCase());
      }
    },
    isBoat(id, label) {
      return (
        id.startsWith("HSLlautta") ||
        (id.startsWith("HSL:10") && ["19", "19E"].includes(label))
      );
    },
    isMetro(label) {
      return ["M1", "M2"].includes(label);
    },
    isTrain(label) {
      return /[a-\xf6]/i.test(label);
    },
    isTram(id, label) {
      return id.startsWith("HSL:10") && parseInt(label, 10) <= 10;
    },
    formatValid(from, to) {
      const now = moment();
      const momentFrom = moment(from);
      const momentTo = moment(to);
      const fromToSame = momentFrom === momentTo;

      if (fromToSame || !to) {
        return momentFrom.format("l") + " alkaen";
      } else if (0 === momentFrom.diff(momentTo, "days")) {
        return (
          "" +
          (0 === now.diff(momentTo, "days")
            ? ""
            : momentFrom.format("l") + " ") +
          ("klo" +
            " " +
            momentFrom.format("LT") +
            " - " +
            momentTo.format("LT"))
        );
      } else {
        return momentFrom.format("l") + " - " + momentTo.format("l");
      }
    },
  },
  computed: {
    important() {
      return this.data.priority.label === "high";
    },
    iconSrc() {
      if (this.data.lines.length === 0) return "hsl";

      const id = this.data.lines[0].gtfsId;
      const label = this.data.lines[0].label;

      if (this.isBoat(id, label)) {
        return "boat";
      } else if (this.isMetro(label)) {
        return "metro";
      } else if (this.isTrain(label)) {
        return "train";
      } else if (this.isTram(id, label)) {
        return "tram";
      } else {
        return "bus";
      }
    },
  },
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.traffic-item {
  width: 100%;
  min-height: 105px;

  border-bottom: 1px solid $gray4;

  padding: 20px 5px;

  display: flex;
  flex-direction: column;

  background-color: $white;

  .top-text {
    display: flex;

    margin-bottom: 10px;

    .time {
      font-family: "Gotham Rounded Book";
      font-size: 13px;
      color: $gray1;
      letter-spacing: -0.5px;
    }
    .tag {
      font-weight: bold;
      font-size: 12px;
      color: $gray1;
      letter-spacing: -0.5px;
      margin-left: 5px;
    }
  }
  .other-info {
    display: flex;
    flex: 1;

    .icon-wrapper {
      height: 40px;
      position: relative;
      &.info::after {
        content: url("../../assets/icons/traffic/info.svg");

        width: 16px;
        height: 16px;

        z-index: 22;

        position: absolute;
        bottom: 0;
        left: 0;

        transform: translate(-50%, 50%);
      }
      &.warning::after {
        content: url("../../assets/icons/traffic/warning.svg");

        width: 15px;
        height: 12px;

        z-index: 22;

        position: absolute;
        bottom: 3px;
        left: 0;

        transform: translate(-50%, 50%);
      }
    }

    .text {
      flex: 1;

      display: flex;
      flex-direction: column;

      margin-left: 15px;

      .details {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        .lines {
          display: flex;
          flex-wrap: wrap;
          &.bus {
            color: $blue;
          }
          &.tram {
            color: $dgreen;
          }
          &.metro {
            color: $orange;
          }
          &.train {
            color: $violet;
          }
          &.boat {
            color: $lblue;
          }

          .line {
            font-family: "Gotham XNarrow";
            font-weight: bold;
            font-size: 22px;

            margin-right: 5px;

            &:last-child {
              margin-right: 10px;
            }
          }
        }
        .valid-from-to {
          font-size: 15px;
          letter-spacing: -0.5px;
        }
      }
      .title {
        flex: 1;

        font-family: "Gotham Rounded Book";
        font-size: 15px;
        letter-spacing: -0.5px;

        margin-top: 5px;
      }
    }
  }
}
</style>
