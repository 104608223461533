<template>
  <div
    class="wrapper"
    :class="{ nospin: !shouldSpin(), svgspin: svgOn }"
    :style="{ backgroundColor: color }"
  >
    <video
      loop
      muted
      autoplay
      playsinline
      preload
      poster="/spinner/spinner.png"
      v-if="!svgOn"
    >
      <source src="/spinner/spinner.mov" type="video/quicktime" />
      <source src="/spinner/spinner.webm" type="video/webm" />
    </video>
    <div class="left" v-show="!shouldSpin()">
      <div class="border expired" v-if="expired">
        <span class="title">Matka kesken?</span>
        <span class="link">Toimi näin</span>
      </div>
    </div>
    <div class="overlay">
      <span class="area">{{ area }}</span>
      <div class="link" @click="openModal()">
        <img src="@/assets/icons/info.svg" /><span>Matkustusalue</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Spin",
  computed: {
    ...mapGetters(["color", "svgOn"]),
  },
  methods: {
    openModal() {
      this.$store.commit("openModal", this.area);
    },
    shouldSpin() {
      return !this.expired;
    },
  },
  props: ["area", "expired"],
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 120px;

  margin-top: 25px;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  position: relative;

  overflow: hidden;

  &.svgspin {
    background-image: url("../../../assets/elements/spinner.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  video {
    width: 100%;
    height: 100%;
  }

  &.nospin {
    &.svgspin {
      background-image: none;
    }
    background-color: #fafafa !important;

    .left {
      width: 40%;
      height: 100%;

      position: absolute;

      left: 0;
      top: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 10px 0;
      .border {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        border-right: 1px solid $gray4;

        &.expired {
          .title {
            margin-bottom: 10px;

            font-size: 15px;
          }
          .link {
            font-size: 14px;

            color: $blue;

            &:active {
              opacity: 0.25;
            }
          }
        }
      }
    }
  }
  .overlay {
    position: absolute;

    right: 0;
    top: 0;

    width: 60%;
    height: 100%;

    border-bottom-right-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .area {
      font-size: 72px;
      font-weight: bold;
    }
    .link {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 5px;
      }
      span {
        font-size: 12px;

        color: $blue;
      }
      &:active {
        opacity: 0.25;
      }
    }
  }
}
</style>
