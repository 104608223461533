<template>
  <router-link :to="linkTo" class="link-wrap">
    <span :class="{ active: active }"><slot></slot></span>
  </router-link>
</template>

<script>
export default {
  name: "Nav",
  computed: {
    active() {
      return this.$route.fullPath == this.linkTo;
    },
  },
  props: ["linkTo"],
};
</script>

<style lang="scss" scoped>
.link-wrap {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
}
span {
  width: 75%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  font-weight: normal;
  font-size: 14px;

  color: rgba($white, 0.75);

  &:active {
    color: dim($white);
  }
  &.active {
    color: $white;
    border-bottom: 3px solid $white;
  }
}
</style>
