<template>
  <Header :links="links" />

  <Message v-show="messageOn" />

  <div class="ticket-wrapper">
    <div class="ticket-container">
      <router-view v-slot="{ Component }">
        <transition :name="animationName">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Message from "@/components/message/Message";

export default {
  name: "Ticket",
  data: () => ({
    messageOn: false,
    messagesWait: 1000 * 10,
    messagesPause: false,
    animationName: undefined,
    links: [
      {
        linkTo: "/hsl/ticket/buy",
        title: "Osta lippu",
      },
      {
        linkTo: "/hsl/ticket/display",
        title: "Lippusi",
      },
    ],
  }),
  methods: {
    showMessage() {
      if (this.messagesPause) {
        return;
      } else {
        this.messageOn = true;
        this.messagesPause = true;
        setTimeout(() => {
          this.messagesPause = false;
          this.messageOn = false;
        }, this.messagesWait);
      }
    },
  },
  created() {
    this.$store.subscribeAction({
      after: action => {
        if (action.type === "fetchColor") {
          this.showMessage();
        }
      },
    });
  },
  watch: {
    $route(to) {
      this.animationName = to.name === "Buy" ? "slide-left" : "slide-right";
    },
  },
  components: { Header, Message },
};
</script>

<style lang="scss">
.ticket-wrapper {
  width: 100vw;

  padding-top: $header-h;
  padding-top: calc(#{$header-h} + env(safe-area-inset-top));

  .ticket-container {
    width: 100%;

    padding: $main-container-padding $main-container-padding;

    background-color: $background;
  }
}
</style>

<style scoped>
div[class*="-active"] {
  transition: all 150ms ease-out;
}
div[class*="slide-"] {
  position: absolute;
}

.slide-left-enter-to,
.slide-right-leave-from {
  left: 0;
}

.slide-left-enter-from,
.slide-right-leave-to {
  left: -100%;
}

.slide-left-leave-to,
.slide-right-enter-from {
  right: -100%;
}

.slide-left-leave-from,
.slide-right-enter-to {
  right: 0;
}
</style>
