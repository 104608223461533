<template>
  <div class="traffic-wrapper">
    <div class="traffic-header">
      <div
        class="img-wrapper"
        v-for="(vehicle, index) in vehicles"
        :key="index"
        :class="coinFlip() ? 'warning' : 'info'"
      >
        <img
          :src="require('@/assets/icons/traffic/header/' + vehicle + '.svg')"
        />
      </div>
    </div>
    <div class="traffic-news-wrapper">
      <TrafficItem
        v-for="news in traffic"
        :key="news.id"
        :data="news"
      ></TrafficItem>
    </div>
  </div>
</template>

<script>
import TrafficItem from "@/components/traffic/TrafficItem.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "Traffic",
  data: () => ({
    vehicles: ["bus", "tram", "metro", "train", "boat", "bike"],
    loading: true,
  }),
  async mounted() {
    if (this.traffic.length === 0) await this.fetchTraffic();
    this.$nextTick(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["fetchTraffic"]),
    coinFlip: () => Math.random() < 0.5,
  },
  computed: {
    ...mapState(["traffic"]),
  },
  components: { TrafficItem },
};
</script>

<style lang="scss" scoped>
.traffic-header {
  width: 100%;
  height: 80px;

  position: fixed;
  z-index: 77;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $background;
  border-bottom: 1px solid $gray4;

  .img-wrapper {
    img {
      border: 1px solid $gray4;
    }
    &:nth-child(odd) img {
      border-right: none;
    }
    &:first-child img {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:last-child img {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    position: relative;

    &.info::after {
      content: url("../../../assets/icons/traffic/info.svg");

      width: 16px;
      height: 16px;

      position: absolute;

      left: 3px;
      bottom: -9px;

      transform: translateY(-50%);
    }
    &.warning::after {
      content: url("../../../assets/icons/traffic/warning.svg");

      width: 15px;
      height: 12px;

      position: absolute;

      left: 3px;
      bottom: -6px;

      transform: translateY(-50%);
    }
  }
}

.traffic-wrapper {
  width: 100%;
  height: 100%;

  position: relative;
}

.traffic-news-wrapper {
  width: 100%;
  height: 100%;

  padding: 80px 15px 0;

  background-color: $white;
}
</style>
