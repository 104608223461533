<template>
  <div class="header-title" ref="header">
    <div class="header-wrap">
      <router-link to="/hsl/ticket/display">
        <img src="@/assets/icons/arrow-back.svg" class="back" />
      </router-link>
      <span>Ostoshistoria</span>
    </div>
  </div>
  <div class="history-wrap">
    <div class="month" v-for="(month, index) in months" :key="index">
      <div class="month-title">
        <span class="month-name">{{ month.name }}</span>
        <span @click="checkMonth(month)" class="check">
          <img src="@/assets/icons/history-checked.svg" v-if="month.checked" />
          <img src="@/assets/icons/history-check.svg" v-else />
        </span>
      </div>
      <div
        class="purchase"
        v-for="(purchase, index) in month.purchases"
        :key="index"
      >
        <div class="left">
          <span class="type">{{ purchase.type }} - {{ purchase.area }}</span>
          <span class="time">{{ formatDate(purchase.date) }}</span>
          <div class="bottom">
            <span class="price">{{ purchase.price }}</span>
            <span class="alv">Sis. alv. 10 %</span>
          </div>
        </div>
        <div class="right">
          <img src="@/assets/icons/history-caret.svg" />
          <img src="@/assets/icons/history-seperate.svg" />
          <div @click="purchase.checked = !purchase.checked">
            <img
              src="@/assets/icons/history-checked.svg"
              v-if="purchase.checked"
            />
            <img src="@/assets/icons/history-check.svg" v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  format,
  getMonth,
  subMonths,
  getYear,
  subYears,
  isBefore,
  isAfter,
} from "date-fns";
import subDays from "date-fns/sub_days/index.js";

export default {
  name: "History",
  data: () => ({
    monthNames: [
      "Tammikuu",
      "Helmikuu",
      "Maaliskuu",
      "Huhtikuu",
      "Toukokuu",
      "Heinäkuu",
      "Kesäkuu",
      "Elokuu",
      "Syyskuu",
      "Lokakuu",
      "Marraskuu",
      "Joulukuu",
    ],
    exampleTickets: [
      {
        type: "Kertalippu",
        area: "AB",
        price: "1,40 €",
      },
      {
        type: "Kertalippu",
        area: "AB",
        price: "2,80 €",
      },
      {
        type: "Kertalippu",
        area: "BC",
        price: "1,40 €",
      },
      {
        type: "Kertalippu",
        area: "BC",
        price: "2,80 €",
      },
      {
        type: "Kertalippu",
        area: "CD",
        price: "1,60 €",
      },
      {
        type: "Kertalippu",
        area: "CD",
        price: "3,20 €",
      },
      {
        type: "Kertalippu",
        area: "D",
        price: "1,40 €",
      },
      {
        type: "Kertalippu",
        area: "D",
        price: "2,80 €",
      },
      {
        type: "Kertalippu",
        area: "ABC",
        price: "2,10 €",
      },
      {
        type: "Kertalippu",
        area: "ABC",
        price: "4,10 €",
      },
    ],
    months: [],
  }),
  methods: {
    generatePurchases() {
      const endDate = subDays(new Date(), 1);
      const startDate = subMonths(endDate, 9);

      const dates = Array.from({ length: 140 }, () =>
        this.randomDate(startDate, endDate)
      );
      let months = dates.reduce((r, a) => {
        const am = getMonth(a);
        r[am] = r[am] || [];
        r[am].push(a);
        return r;
      }, Object.create(null));

      for (let key in months) {
        months[key] = {
          checked: false,
          name:
            key > getMonth()
              ? this.monthNames[key] + " " + getYear(subYears(new Date(), 1))
              : this.monthNames[key],
          purchases: months[key].map(m => ({
            checked: false,
            date: m,
            ...this.randomTicket(),
          })),
        };
      }

      let monthArr = Object.values(months);
      isAfter(new Date(), new Date());
      isBefore(new Date(), new Date());

      monthArr.sort((a, b) =>
        isAfter(a.purchases[0].date, b.purchases[0].date) ? -1 : 1
      );

      for (let month of monthArr) {
        month.purchases.sort((a, b) => (isAfter(a.date, b.date) ? -1 : 1));
      }

      this.months = monthArr;
    },
    randomTicket() {
      return this.exampleTickets[
        (Math.random() * this.exampleTickets.length) | 0
      ];
    },
    randomDate(start, end) {
      return new Date(
        start.getTime() + Math.random() * (end.getTime() - start.getTime())
      );
    },
    checkMonth(month) {
      const changed = !month.checked;
      month.checked = changed;
      for (const p of month.purchases) {
        p.checked = changed;
      }
    },
    formatDate(date) {
      return format(date, "D.M.YYYY [klo] HH:mm");
    },
  },
  mounted() {
    this.$refs.header.addEventListener(
      "touchmove",
      function(e) {
        e.preventDefault();
      },
      false
    );
    this.generatePurchases();
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  width: 100%;
  position: fixed;
  z-index: 99;

  top: 0;
  left: 0;

  box-sizing: content-box;
  padding-top: env(safe-area-inset-top);

  height: $header-h;

  background-color: $blue;

  .header-wrap {
    width: 100%;
    height: 100%;

    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: $white;

      font-size: 19px;
    }
    .back {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.history-wrap {
  margin-top: calc(#{$header-h} + env(safe-area-inset-top));
  width: 100%;
  height: auto;
  position: relative;

  .month {
    .purchase {
      width: 100%;
      height: 80px;

      background-color: $white;

      border-bottom: 1px solid $gray4;

      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0 20px;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .type {
          font-size: 14px;
          font-weight: 500;
        }
        .time,
        .alv {
          font-size: 12px;
          font-family: "Gotham Rounded Book";
          color: $gray1;
        }
        .price {
          font-size: 12px;
          font-weight: 500;
          margin-right: 3px;
        }
      }
      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & > * {
          margin-left: 20px;
        }
      }
    }
  }

  .month-title {
    width: 100%;
    height: 50px;

    position: sticky;
    top: calc(#{$header-h} + env(safe-area-inset-top));

    padding: 0 20px;

    background: $background;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $gray4;

    .month-name {
      font-size: 14px;
      font-family: "Gotham Rounded Book";
      color: $black;
    }
  }
}
</style>
