<template>
  <div class="map" id="map"></div>
  <div class="widget" ref="widget">
    <div
      class="draggable-container"
      @touchstart.prevent="dragStart"
      @mousedown.prevent="dragStart"
      @touchend.prevent="dragEnd"
      @mouseup.prevent="dragEnd"
      @touchmove.prevent="drag"
      @mousemove.prevent="drag"
    >
      <div class="draggable"></div>
    </div>
    <div class="widget-container">
      <p class="guide-title">Minne mennään?</p>
      <div class="input current">
        <div class="svg-cont">
          <img src="@/assets/icons/current.svg" />
        </div>
        <span>Nykyinen sijaintisi</span>
      </div>
      <div class="bridge">
        <div class="bridge-icon"></div>
      </div>
      <div class="input dest">
        <div class="svg-cont">
          <img src="@/assets/icons/dest.svg" />
        </div>
        <span>Syötä määränpää</span>
      </div>
      <div class="choose-time">
        <img src="@/assets/icons/guide-clock.svg" class="clock" />
        <span>Lähtö nyt</span>
        <img src="@/assets/icons/guide-caret.svg" class="caret" />
      </div>
      <div class="add-bar">
        <div class="add">
          <div class="svg-cont">
            <img src="@/assets/icons/guide-home.svg" />
          </div>
          <span>Lisää koti</span>
        </div>
        <div class="add">
          <div class="svg-cont">
            <img src="@/assets/icons/guide-work.svg" />
          </div>
          <span>Lisää työ</span>
        </div>
        <div class="add">
          <div class="svg-cont">
            <img src="@/assets/icons/guide-other.svg" />
          </div>
          <span>Lisää muu paikka</span>
        </div>
      </div>
      <div class="lines">
        <p class="indent-title">Linja- ja pysäkkihaku</p>
        <div class="input">
          <div class="svg-cont">
            <img src="@/assets/icons/guide-search.svg" />
          </div>
          <span>Linja, pysäkki tai asema</span>
        </div>
      </div>
      <div class="line">
        <div class="inset-line">
          <p class="line-title">Omat linjat</p>
          <div class="bottom">
            <div class="line-svg">
              <img src="@/assets/icons/guide-star.svg" />
            </div>
            <span class="line-text">Ei omia linjoja</span>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="inset-line">
          <p class="line-title">Omat pysäkit</p>
          <div class="bottom">
            <div class="line-svg">
              <img src="@/assets/icons/guide-star.svg" />
            </div>
            <span class="line-text">Ei omia pysäkkejä</span>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="inset-line">
          <p class="line-title">Omat pyöräasemat</p>
          <div class="bottom">
            <div class="line-svg">
              <img src="@/assets/icons/guide-star.svg" />
            </div>
            <span class="line-text">Ei omia pyöräasemia</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const screenHeight = window.document.documentElement.clientHeight;
const footerHeight = 50;
const draggableHeight = 20;

export default {
  name: "Guide",
  data: () => ({
    dragging: false,
    yOffset: 0,
    initialY: 0,
    currentY: 0,
  }),
  methods: {
    setUpMap() {
      const map = L.map("map", {
        zoomControl: false,
        attributionControl: false,
      }).setView([60.192059, 24.945831], 13);

      L.tileLayer("https://cdn.digitransit.fi/map/v1/{id}/{z}/{x}/{y}@2x.png", {
        maxZoom: 19,
        tileSize: 512,
        zoomOffset: -1,
        id: "hsl-map",
      }).addTo(map);
    },
    dragStart(e) {
      this.$refs.widget.style.height =
        0.9 * (screenHeight - footerHeight) + "vh";

      if (e.type === "touchstart") {
        this.initialY = e.touches[0].clientY - this.yOffset;
      } else {
        this.initialY = e.clientY - this.yOffset;
      }

      this.dragging = true;
    },
    dragEnd() {
      this.initialY = this.currentY;
      this.dragging = false;

      const height =
        screenHeight - footerHeight + draggableHeight - this.initialY + "px";
      this.$refs.widget.style.height = height;
    },
    drag(e) {
      if (this.dragging) {
        let currentY;
        if (e.type === "touchmove") {
          currentY = e.touches[0].clientY - this.initialY;
        } else {
          currentY = e.clientY - this.initialY;
        }

        if (
          currentY < screenHeight - footerHeight - draggableHeight &&
          currentY > screenHeight * 0.1
        ) {
          this.yOffset = currentY;
          this.currentY = currentY;
          this.setTranslate();
        }
      }
    },
    setTranslate() {
      this.$refs.widget.style.transform = `translate3d(0, ${this.currentY}px, 0)`;
    },
  },
  mounted() {
    this.setUpMap();
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100vw;
  height: 100vh;
}
.widget {
  width: 100vw;
  height: calc(90vh - #{$footer-h});

  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;

  transform: translate3d(0, 10vh, 0);

  background-color: $white;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  .draggable-container {
    width: 100%;
    height: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    .draggable {
      width: 30px;
      height: 4px;

      background-color: $gray3;
      border-radius: 2px;
    }
  }
  .widget-container {
    max-height: calc(100% - 15px);
    overflow-y: scroll;

    padding: 0 15px;
    p.guide-title {
      font-size: 24px;
      margin: 0 0 17px 15px;
    }
    .input {
      width: 100%;
      height: 42px;

      border-radius: 8px;
      border: 1px solid $border;

      background-color: $background;

      font-family: "Gotham Rounded Book";
      font-size: 14px;

      display: flex;
      align-items: center;

      .svg-cont {
        width: 50px;
        height: 100%;

        display: grid;
        place-content: center;
      }
    }
    .dest {
      span {
        color: $gray1;
      }
    }
    .bridge {
      display: block;
      position: relative;
      height: 9px;
      z-index: 19;
      .bridge-icon {
        position: absolute;
        width: 4px;
        height: 18px;
        border-radius: 2px;
        background-color: $gray4;
        margin-left: 25px;
        margin-top: -4px;
      }
    }
    .choose-time {
      width: 100%;
      height: 38px;

      margin: 12px 0 12px 15px;

      display: flex;
      align-items: center;

      .clock {
        width: 18px;
        height: 19px;
      }
      span {
        color: $blue;
        margin-left: 9px;
      }
      .caret {
        width: 14px;
        height: 10px;
        margin-left: 9px;
      }
    }
    .add-bar {
      width: 100vw;
      height: 55px;

      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 0;
        width: 0;
        background: transparent;
      }

      margin-bottom: 15px;
      margin-left: -15px;

      display: flex;

      .add {
        height: 100%;

        flex-shrink: 0;

        margin-right: 8px;

        &:first-child {
          margin-left: 15px;
        }

        padding-right: 15px;

        background-color: $white;

        border: 1px solid $border;
        border-radius: 10px;

        display: flex;
        align-items: center;

        .svg-cont {
          width: 50px;
          height: 100%;

          display: grid;
          place-content: center;

          img {
            width: auto;
            height: 18px;
          }
        }
      }
    }
    .lines {
      margin-bottom: 5px;
      .indent-title {
        margin-left: 15px;
        margin-bottom: 20px;

        font-size: 18px;
      }
      .input {
        .svg-cont {
          width: 50px;

          img {
            height: 17px;
          }
        }
        span {
          color: $gray1;
        }
      }
    }
    .line {
      width: 100%;

      padding: 16px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $border;
      }

      .inset-line {
        padding: 0 15px;

        .bottom {
          margin-top: 15px;
          display: flex;

          .line-svg {
            width: 30px;

            display: grid;
            place-content: center;

            img {
              height: 18px;
            }
          }
          .line-text {
            margin-left: 5px;

            font-family: "Gotham Rounded Book";
          }
        }
      }
    }
  }
}
</style>
