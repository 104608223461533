<template>
  <div class="wrapper">
    <img :src="require('@/assets/icons/' + ticketType + '.svg')" />
    <span v-if="ticketType === 'single'" class="type">KERTALIPPU</span>
    <span v-if="ticketType === 'period'" class="type">KAUSILIPPU - 30 vrk</span>
    <span v-if="ticketType === 'week'" class="type">VUOROKAUSILIPPU</span>
  </div>
</template>

<script>
export default {
  name: "Type",
  data: () => ({
    type: "",
  }),
  props: ["ticketType"],
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 50px;

  border: 1px solid $gray4;
  border-right: none;
  border-left: none;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 15px;

  img {
    margin: 0 9px;
  }
  span.type {
    font-size: 17px;
    font-weight: bold;
  }
}
</style>
