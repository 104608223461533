<template>
  <Frame>
    <div class="parent">
      <Qr
        :qrId="preferences.qr"
        :leftDigits="preferences.leftDigits"
        class="qr"
      />
      <Type :ticketType="preferences.type" />
      <Info
        :expiry="preferences.expiry"
        :expired="preferences.expired"
        :age="preferences.age"
        :ticketType="preferences.type"
      />
      <Spin :area="preferences.area" :expired="preferences.expired" />
    </div>
  </Frame>
</template>

<script>
import Qr from "./Qr";
import Type from "./Type";
import Info from "./Info";
import Spin from "./Spin";
import Frame from "./Frame";

import { isFuture } from "date-fns";

export default {
  name: "Ticket",
  data: () => ({
    checkInterval: null,
  }),
  mounted() {
    if (!this.preferences.expired) {
      const checkExpiryInterval = 1000 * 5;
      this.checkInterval = setInterval(this.checkExpiry, checkExpiryInterval);
      this.checkExpiry();
    }
  },
  methods: {
    checkExpiry() {
      const expiry = new Date(this.preferences.expiry);
      if (!isFuture(expiry)) {
        this.$store.commit("expireTicket", this.preferences.id);
        clearInterval(this.checkInterval);
      }
    },
  },
  components: { Qr, Type, Info, Spin, Frame },
  props: ["preferences"],
};
</script>

<style lang="scss" scoped>
.parent {
  width: 100%;
  height: 100%;

  padding: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .qr {
    flex: 1;
  }
}
</style>
