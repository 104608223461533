<template>
  <div class="news-item" :class="isMini ? 'mini' : 'big'">
    <img :src="data?.images[0]?.url" v-show="hasImg" />
    <div class="text-wrapper">
      <div class="text">
        <span class="date">{{ formatDate(data.created) }}</span>
        <span class="tag" v-show="!isMini">HYVÄ TIETÄÄ</span>
      </div>
      <span class="title">{{ data.label }}</span>
    </div>
  </div>
</template>

<script>
import { format, isToday } from "date-fns";

export default {
  name: "NewsItem",
  methods: {
    formatDate(date) {
      if (isToday(date)) {
        return format(date, "HH:mm");
      } else {
        return format(date, "D.M.YYYY");
      }
    },
  },
  computed: {
    hasImg() {
      return this.data.images.length > 0;
    },
    isMini() {
      return this.data.priority.label == "normal";
    },
  },
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.news-item {
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-bottom: 15px;

  img {
    width: 100%;
    height: 194px;

    object-fit: cover;

    border-radius: 2px;

    margin: 15px 0;
  }

  .text-wrapper {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .text {
    .date,
    .tag {
      font-size: 12px;
      color: $gray1;

      &.date {
        font-family: "Gotham Rounded Book";
        margin-right: 10px;
      }
      &.tag {
        font-weight: bold;
      }
    }
  }
  .text,
  .title {
    margin: 5px;
  }

  &:active {
    opacity: 0.75;
  }

  &.mini {
    flex-direction: row-reverse;
    padding: 0 5px;
    img {
      width: 85px;
      height: 85px;
      margin-left: 10px;
    }
    .title {
      font-size: 15px;
      letter-spacing: -0.5px;
    }
  }
  &.big {
    .title {
      font-size: 18px;
      letter-spacing: -0.6px;
    }
  }
}
</style>
