<template>
  <router-link :to="linkTo" class="nav" v-slot="{ isActive }">
    <img
      :src="
        require('@/assets/icons/' +
          iconName +
          (isActive || isAlso ? '-a' : '') +
          '.svg')
      "
    />
    <span :class="{ active: isActive || isAlso }"><slot></slot></span>
  </router-link>
</template>

<script>
export default {
  props: {
    iconName: String,
    linkTo: String,
    also: { type: Array, default: () => [] },
  },
  computed: {
    isAlso() {
      return this.also.includes(this.$route.fullPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  span {
    font-size: 10px;
    font-weight: bold;

    color: $gray1;

    &.active {
      color: $blue;
    }
  }
}
</style>
