<template>
  <div class="wrapper">
    <router-view></router-view>

    <Footer />
    <Modal />
  </div>
</template>

<script>
import Footer from "../components/footer/Footer";
import Modal from "../components/ticket/Modal";

import { mapState } from "vuex";

export default {
  name: "Hsl",

  created() {
    this.blackout(this.blackoutMode);
  },
  watch: {
    blackoutMode(newVal) {
      this.blackout(newVal);
    },
  },
  methods: {
    blackout(bool) {
      const html = document.querySelector("html").classList;
      if (bool) {
        html.add("blackout");
      } else {
        html.remove("blackout");
      }
    },
  },
  computed: mapState(["color", "blackoutMode"]),
  components: { Footer, Modal },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;

  position: relative;

  padding-bottom: $footer-h;
  padding-bottom: calc(#{$footer-h} + env(safe-area-inset-bottom));

  background-color: $background;
}
</style>
