<template>
  <div
    class="darkness"
    :class="{ show: active, hide: fadeOut && active }"
    ref="darkness"
  >
    <div class="modal">
      <span class="header">{{ modalArea }}-lipun matkustusalue</span>
      <img :src="require('@/assets/maps/' + modalArea + '.png')" class="map" />
      <span class="title">Reittikohtaisen lipputarpeesi näet</span>
      <span class="link" @click="openSettings()"
        >HSL:n reittioppaasta <img src="@/assets/icons/caret.svg"
      /></span>
      <button class="close" @click="close()">Sulje</button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Modal",
  data: () => ({
    fadeOut: false,
  }),
  computed: mapState({ active: "modalOpen", modalArea: "modalArea" }),
  methods: {
    close() {
      this.fadeOut = true;
      setTimeout(() => {
        this.$store.commit("closeModal");
        this.fadeOut = false;
      }, 800);
    },
    openSettings() {
      this.$router.push("/hidden/settings");
    },
  },
  watch: {
    active: function() {
      document.body.style.overflowY = this.active ? "hidden" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.darkness {
  display: none;

  position: fixed;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  z-index: 2222;

  background-color: rgb(11, 11, 11, 0.55);

  .modal {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, 0%);
    opacity: 0;

    width: 300px;
    height: 485px;

    background-color: $white;

    border-radius: 8px;

    padding: 30px 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    .header {
      margin-top: 5px;

      font-size: 17px;
    }
    img.map {
      width: 100%;
      height: auto;

      margin: 15px 0;
    }
    .title {
      font-size: 15px;
    }
    .link {
      flex: 1;

      font-size: 15px;

      color: $blue;

      &:active {
        opacity: 0.25;
      }
    }
    .close {
      width: 260px;
      height: 40px;

      background-color: transparent;

      color: $gray1;
      font-size: 17px;

      border: 1px solid $gray1;
      border-radius: 20px;

      &:active {
        opacity: 0.25;
      }
    }
  }
  &.show {
    display: block;

    animation: overlay-show 500ms ease;
    animation-fill-mode: forwards;

    .modal {
      animation: modal-show 600ms ease-out;
      animation-delay: 500ms;
      animation-fill-mode: forwards;
    }

    @keyframes overlay-show {
      0% {
        background-color: rgb(11, 11, 11, 0);
      }
      100% {
        background-color: rgb(11, 11, 11, 0.55);
      }
    }
    @keyframes modal-show {
      0% {
        transform: translate(-50%, 0%);
        opacity: 0;
      }
      100% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  &.hide {
    display: block;

    animation: overlay-hide 800ms ease-out;
    animation-fill-mode: forwards;
    @keyframes overlay-hide {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}
</style>
