<template>
  <div class="daddy">
    <div class="content" v-if="!loading">
      <slot></slot>
    </div>
    <div class="loading" v-else>
      <img src="@/assets/icons/loading.svg" />
    </div>
    <img class="bg-img" src="@/assets/elements/ticket-bg.svg" />
  </div>
</template>

<script>
export default {
  name: "Frame",
  data: () => ({
    loading: true,
  }),
  mounted() {
    const randomLoadTime = 500 + Math.floor(Math.random() * 500);
    setTimeout(() => (this.loading = false), randomLoadTime);
  },
};
</script>

<style lang="scss" scoped>
.daddy {
  position: relative;

  margin-bottom: 15px;

  .content,
  .loading {
    width: 100%;
    height: 100%;

    z-index: 11;

    position: absolute;
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 40px;
      animation: load 800ms infinite linear;
      @keyframes load {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(359deg);
        }
      }
    }
  }
  .bg-img {
    width: 100%;
    -webkit-filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
  }
}
</style>
