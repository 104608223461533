<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "@/styles/fonts.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  color: $black;
  background-color: $background;

  overscroll-behavior-y: contain;
}
html::-webkit-scrollbar {
  display: none;
}
body {
  min-height: 100vh;

  -webkit-font-smoothing: antialiased;

  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
body,
button {
  font-family: "Gotham Rounded";
  //letter-spacing: -3%; ?
}

html.blackout {
  @media only screen and (min-width: 450px),
    only screen and (orientation: landscape) {
    background-color: #000000 !important;
    width: 100vw !important;
    height: 100vh !important;

    body {
      display: none !important;
    }
  }
}

.ios-wrap {
  padding: env(safe-area-inset-top) 0 env(safe-area-inset-bottom);
}
</style>
